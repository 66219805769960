@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@300;400;700&display=swap');

*,
*::before,
*::after {
  --newRed: #ea3a3a;
  --newBlue: #4758d6;
  --newBrg: #e1811f;
  --blk: #000000;
  --wht: #ffffff;
  --gry: #5f6369;
  --drGry: #6b6b6b;

  box-sizing: border-box;
}

body {
  background-color: var(--wht);
  color: var(--blk);
  font-family: 'Merriweather Sans', sans-serif;
}
