.banner {
  position: relative;
  text-align: center;
}

.banner__image {
  width: 100vw;
  height: 75%;
}

.banner__title {
  font-weight: 700;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* background-color: var(--newRed); */
  background-color: white;
  /* color: white; */
  color: black;
  width: 70%;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 0.8rem;
}

.banner__cta {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #000;
}

.banner__ctaText {
  position: relative;
  top: 1px;
}


@media (max-width: 438px) {
  .banner__title {
    /* display: none; */
    width: 100%;
  }

  .banner__cta {
    top: 70%;
    transform: translate(-50%, -50%) scale(1.5);
  }
}

@media (min-width: 516px) {
  .banner__cta {
    transform: translate(-50%, -50%) scale(1.3);
  }
}

@media (min-width: 640px) {
  .banner__title {
    top: 30%;
    width: 80%;
  }

  .banner__cta {
    transform: translate(-50%, -50%) scale(1.5);
  }
}

@media (min-width: 965px) {
  .banner__cta {
    transform: translate(-50%, -50%) scale(2);
  }
}
