.navbar__phone {
  display: flex;
}

/* Media Query */
@media screen and (max-width: 767px) {
  .nav-list {
    position: absolute;
    width: 100%;
    background: white;
    transform: translateY(-160%);
    transition: 0.5s ease-in;
    z-index: -1000;
    right: 0px;
  }

  .nav-active {
    transform: translateY(62.2%);
  }
}

@media screen and (max-width: 525px) {
  .navbar__phone {
    display: none;
  }

  .nav-active {
    transform: translateY(61.2%);
  }
}
